import { Chart } from 'react-chartjs-2'

import { isArray } from 'lodash'
import {
  calcYByVal,
  drawArrowLabel,
  drawBreakPointLabel,
  drawCircleBadge,
  drawColourBar,
  drawHighlightArea,
  drawPatternArea,
  drawRectOuterShadow,
  drawStandrdLine,
  drawVerticalGreenLine
} from 'utils/canvas'

// defaults.global.events = [];
// defaults.global.tooltips.enabled = false;

const beforePrintHandler = () => {
  for (let id in Chart.instances) {
    Chart.instances[id].resize()
  }
}

if (window.matchMedia) {
  let mediaQueryList = window.matchMedia('print')
  mediaQueryList.addListener(mql => {
    if (mql.matches) {
      beforePrintHandler()
    }
  })
}
window.onbeforeprint = beforePrintHandler

// let renderCount = 1;
Chart.pluginService.register({
  id: 'bill',
  beforeDatasetsDraw: (chart, num, options) => {
    const { top, left, right, bottom } = chart.chartArea
    const chartAreaWidth = right - left
    const chartAreaHeight = bottom - top
    const len = chart.config.data.labels.length - 1
    const pieceWidth = chartAreaWidth / len
    let highlightWidth = 0
    let startX = 0
    // 渲染工作日高亮区域底色
    if (options.range && options.range.length) {
      chart.ctx.save()
      chart.ctx.fillStyle = 'rgba(255,255,255,0.8)'
      chart.ctx.fillRect(left, top - 11, chartAreaWidth + 1, 11)
      chart.ctx.restore()
      options.range.forEach((item, i) => {
        highlightWidth = pieceWidth * (item[1] - item[0])
        startX = left + pieceWidth * item[0]
        if (highlightWidth > 0) {
          drawHighlightArea(chart.ctx, startX, top - 10, highlightWidth, chartAreaHeight + 20)
        }
      })
    }
  },
  afterDatasetsDraw: (chart, num, options) => {
    const ctx = chart.ctx
    const { top, left, right, bottom } = chart.chartArea
    const chartAreaWidth = right - left
    const chartAreaHeight = bottom - top
    const len = chart.config.data.labels.length - 1
    const pieceWidth = chartAreaWidth / len
    let lastEndPoint = 0
    let fillWidth = 0
    // 渲染工作日以外阴影区域
    if (options.range) {
      options.range.forEach((item, i) => {
        fillWidth = pieceWidth * (item[0] - lastEndPoint)
        drawPatternArea(ctx, left + pieceWidth * lastEndPoint, top, fillWidth, chartAreaHeight + 1)
        lastEndPoint = item[1]
        if (i === options.range.length - 1) {
          fillWidth = chartAreaWidth - pieceWidth * lastEndPoint
          drawPatternArea(ctx, left + pieceWidth * lastEndPoint, top, fillWidth + 1, chartAreaHeight + 1)
        }
      })
    }
  },
  afterRender: (chart, options) => {
    const ctx = chart.ctx
    const { top, left, right, bottom } = chart.chartArea
    const chartAreaWidth = right - left
    const chartAreaHeight = bottom - top
    const len = chart.config.data.labels.length - 1
    const pieceWidth = chartAreaWidth / len
    let highlightWidth = 0
    let startX = 0
    // 渲染工作日高亮区域外阴影
    if (options.range) {
      options.range.forEach((item, i) => {
        highlightWidth = pieceWidth * (item[1] - item[0])
        startX = left + pieceWidth * item[0]
        if (~~(highlightWidth * chart.currentDevicePixelRatio)) {
          drawRectOuterShadow(
            ctx,
            startX,
            top - 10,
            highlightWidth,
            chartAreaHeight + 20,
            chart.currentDevicePixelRatio
          )
        }
      })
    }
    const heightY = chart.scales['y-axis-0'].height
    const startYVal = chart.scales['y-axis-0'].start
    const maxYVal = chart.scales['y-axis-0'].end

    // 渲染 标准
    if (options.standardVal) {
      // Calculate the relative position based on the value range
      const valueRange = maxYVal - startYVal
      const relativePosition = (options.standardVal - startYVal) / valueRange

      // Ensure the position stays within the chart bounds
      const boundedPosition = Math.max(0, Math.min(1, relativePosition))
      const standrdY = top + heightY * (1 - boundedPosition)

      // Draw the standard line at the calculated position
      if (standrdY >= top && standrdY <= bottom) {
        drawStandrdLine(ctx, left, standrdY, right, standrdY, options.standardVal, options.standardLabel)
      } else {
        // If the standard value is outside the visible range, draw at the top
        drawStandrdLine(ctx, left, 15, right, 15, options.standardVal, options.standardLabel)
      }
    }
    // 渲染渐变色柱状能量指示条
    if (options.colourBar) {
      if (options.multipleYxes) {
        const maxRightYVal = chart.scales['y-axis-1'].end
        const heightRightY = chart.scales['y-axis-1'].height

        const leftColourBar =
          options.colourBar[0] &&
          options.colourBar[0].map((arr, i) => {
            let k1 = parseFloat(arr[0]) / maxYVal
            if (k1 <= 1) {
              if (options.showHealthRating !== false) {
                drawBreakPointLabel(
                  ctx,
                  left + 5, // left 位置
                  top + heightY * (1 - k1), // top 位置
                  arr[2] // label text
                )
              }
            } else {
              k1 = 1
            }
            return [k1, arr[1], arr[2]]
          })

        const rightColourBar =
          options.colourBar[1] &&
          options.colourBar[1].map((arr, i) => {
            let k1 = parseFloat(arr[0]) / maxRightYVal
            if (k1 <= 1) {
              if (options.showHealthRating !== false) {
                drawBreakPointLabel(
                  ctx,
                  right - 5,
                  top + heightRightY * (1 - k1), // top 位置
                  arr[2], // label text
                  true
                )
              }
            } else {
              k1 = 1
            }
            return [k1, arr[1], arr[2]]
          })

        if (leftColourBar) {
          drawColourBar(
            ctx,
            left - 12, // left
            top, // top
            6, // 能量柱宽度
            chartAreaHeight, // 能量柱高度
            leftColourBar // 渐变色参数
          )
        }

        if (rightColourBar) {
          drawColourBar(
            ctx,
            right + 12,
            top, // top
            6, // 能量柱宽度
            chartAreaHeight, // 能量柱高度
            rightColourBar // 渐变色参数
          )
        }
      } else {
        const colourBar = options.colourBar.map((arr, i) => {
          let k1 = parseFloat(arr[0]) / maxYVal
          if (k1 <= 1) {
            if (options.showHealthRating !== false) {
              drawBreakPointLabel(
                ctx,
                left + 5, // left 位置
                top + heightY * (1 - k1), // top 位置
                arr[2] // label text
              )
            }
          } else {
            k1 = 1
          }
          return [k1, arr[1], arr[2]]
        })
        drawColourBar(
          ctx,
          left - 12, // left
          top, // top
          6, // 能量柱宽度
          chartAreaHeight, // 能量柱高度
          colourBar // 渐变色参数
        )
      }
    }
    // 渲染 HIGH & LOW 指标 badge
    if (options.high && options.low) {
      // max: {idx, value, badge}
      ;[options.high, options.low].forEach((e, i) => {
        if (e.value === null) {
          return
        }
        const y = calcYByVal(top, heightY, maxYVal, e.value, startYVal)
        const x = e.idx[0]
        drawCircleBadge(ctx, left + pieceWidth * x, y, e.badge, {
          direction: e.value < -100 ? 'DOWN' : ''
        })
      })
    }
  }
})

Chart.pluginService.register({
  id: 'addArrowLabel',
  afterRender: (chart, options) => {
    const ctx = chart.ctx
    const { top, left, right } = chart.chartArea
    const direction = options.direction || 'DOWN_RIGHT'
    const mutilp = isArray(options.label)

    if (options.label) {
      drawArrowLabel(ctx, left, top, mutilp ? options.label[0] : options.label, direction)
      if (mutilp) {
        drawArrowLabel(ctx, right, top, options.label[1], 'DOWN_LEFT')
      }
    }
    //bug with the 2nd vertical green line
    //every time afterRender runs,  both "drawArrowLabel" & "drawVerticalGreenLine" run
    //the 3 conditions below ensure "drawVerticalGreenLine" runs only with the Kiosk's horizontal chart
    if (chart.id === 1) {
      if (chart.config.type === 'horizontalBar') {
        if (options.direction === 'RIGHT') {
          drawVerticalGreenLine(ctx, left, top)
        }
      }
    }
  }
})

Chart.pluginService.register({
  id: 'doughnut',
  afterDatasetsDraw: (chart, num, options) => {
    if (chart.config.type === 'doughnut' && !chart.active) {
      const firstEle = chart.getDatasetMeta(0).data[options.idx || 0]
      if (firstEle) {
        firstEle._view = Object.assign({}, firstEle._view, {
          borderWidth: firstEle._view.circumference ? 6 : 0
        })
        firstEle.draw()
      }
    }
  }
})
